// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from '@remix-run/css-bundle';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import "./tailwind.css?__remix_sideEffect__";
import favicon from '../public/favicon.svg';
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: cssBundleHref
}] : []), {
  rel: 'icon',
  type: 'image/svg+xml',
  href: favicon
}];
export default function App() {
  return <html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />

				<meta property="og:url" content="https://selestial.co/" />
				<meta property="og:site_name" content="selestial.co" />
				<meta property="og:title" content="selestial" />
				<meta property="og:image" content="https://selestial.co/thumbnail.png" />

				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="627" />
				<meta property="og:type" content="website" />
				<Meta />
				<Links />
			</head>
			<body>
				<Outlet />
				<ScrollRestoration />
				<script dangerouslySetInnerHTML={{
        __html: `window.metricalp = window.metricalp || {queue: [], event: function(e){this.queue.push(e)}}`
      }} />

				<Scripts />
				<LiveReload />
				<script src="https://cdn.metricalp.com/event/metricalp.js" data-tid="mam59" async />

			</body>
		</html>;
}
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;